import { Row, Col } from "reactstrap";
import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormText,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { useDebounce } from "../../../helpers/utils";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { PaginationProvider } from "react-bootstrap-table2-paginator";
import { isIterableArray, getPaginationArray } from "../../../helpers/utils";
import AddressMap from "../map/AddressMap";
import * as XLSX from "xlsx";

const PostBoxes = ({ boxes }) => {
  const [columns, setColumns] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState();
  const debouncedFilter = useDebounce(filter, 300);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("list");
  const [buildingFilter, setBuildingFilter] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Extract unique building names
  const uniqueBuildings = [
    ...new Set((boxes || []).map((item) => item.building_name)),
  ];

  const handleBuildingFilterChange = (event) => {
    console.log("buidlingFilter", event.target.value);
    setBuildingFilter(event.target.value);
  };

  useEffect(() => {
    let columns = [
      {
        dataField: "building_name",
        text: "Bygning",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
        headerFormatter: (column, colIndex, { sortElement }) => {
          return (
            <div className="d-flex flex-column">
              <span>{column.text}</span>
              <select
                id="selectBuilding"
                className="mt-1 rounded-pill"
                onChange={handleBuildingFilterChange}
              >
                <option value="">Alle bygninger</option>
                {uniqueBuildings.map((building) => (
                  <option key={building} value={building}>
                    {building}
                  </option>
                ))}
              </select>
            </div>
          );
        },
      },
      {
        dataField: "box_address",
        text: "Postkasse-ID",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
      },
      {
        dataField: "resident",
        text: "Beboer",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => {
          return cell?.replace(/(?:\\[rn])+/g, "");
        },
        csvFormatter: (cell, row, rowIndex, extraData) => {
          return cell?.replace(/(?:\\[rn])+/g, "") ?? "";
        },
      },
      {
        dataField: "box_serial",
        text: "Serienummer",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
        csvFormatter: (cell, row, rowIndex, extraData) => {
          return cell ?? "";
        },
      },
      {
        dataField: "display_content",
        text: "Tekst på displayet",
        headerClasses: "border-0",
        classes: "border-0 py-2 align-middle",
        sort: true,
        csvFormatter: (cell, row, rowIndex, extraData) => {
          return cell ?? "";
        },
      },
    ];

    setColumns(columns);

    // eslint-disable-next-line
  }, [boxes]);

  const filterByBuilding = (data, filter) => {
    if (!filter) return data;
    return data.filter((item) => item.building_name === filter);
  };

  const filterBySearch = (data, search, columns) => {
    if (!search) return data;
    const searchValue = search.toLowerCase();
    const columnNames = columns.map((c) => c.dataField);
    return data.filter((item) => {
      const values = Object.entries(item).filter(([key]) =>
        columnNames.includes(key)
      );
      return values.some(([key, value]) =>
        String(value).toLowerCase().includes(searchValue)
      );
    });
  };

  useEffect(() => {
    let allFiltered = filterByBuilding(boxes, buildingFilter);
    allFiltered = filterBySearch(allFiltered, debouncedFilter, columns);
    setFiltered(allFiltered);
  }, [debouncedFilter, boxes, columns, buildingFilter]);

  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  if (!boxes || !isIterableArray(columns)) {
    return null;
  }

  const tableOptions = {
    onClick: (e, row, rowIndex) => {
      history.push(`/postbox/${row.box_id}`);
    },
  };

  const MyExportCSV = (props) => {
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret className="btn btn-success mb-2">
          Eksporter til
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => props.onExport()}>CSV</DropdownItem>
          <DropdownItem
            onClick={() => exportToExcel(filtered, "postkasser.xlsx")}
          >
            Excel
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const exportToCSV = (csvData, fileName) => {
    // Add BOM to the beginning of the CSV string
    const BOM = "\uFEFF"; // Byte Order Mark for UTF-8
    const csvContent = BOM + csvData; // Prepend BOM to CSV data

    // Create a Blob with UTF-8 encoding
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterDataForExport = (data, columns) => {
    const columnFields = columns.map((col) => col.dataField);
    return data.map((row) => {
      const filteredRow = {};
      columnFields.forEach((field) => {
        filteredRow[field] = row[field];
      });
      return filteredRow;
    });
  };

  const mapHeaders = (columns) => {
    const headers = {};
    columns.forEach((col) => {
      headers[col.dataField] = col.text;
    });
    return headers;
  };

  const exportToExcel = (data, fileName) => {
    const filteredData = filterDataForExport(data, columns);
    const headers = mapHeaders(columns);
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(headers),
    });
    XLSX.utils.sheet_add_aoa(worksheet, [Object.values(headers)], {
      origin: "A1",
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Postkasser");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <ToolkitProvider
      keyField="box_id"
      data={filtered ?? []}
      columns={columns}
      length={filtered?.length ?? 0}
      exportCSV={{
        fileName: "postkasser.csv",
        ignoreFooter: true,
        onlyExportFiltered: true,
        exportAll: true,
        onExport: (csvData) => {
          exportToCSV(csvData, "postkasser.csv");
        },
      }}
    >
      {(props) => (
        <div>
          <Row>
            <Col style={{ minWidth: "200px" }}>
              <Input
                type="search"
                autoFocus
                placeholder="Filter"
                aria-label="Filter"
                className="rounded-pill search-input"
                value={filter}
                onChange={({ target }) => setFilter(target.value)}
              />
              <FormText
                className="ml-4"
                muted
              >{`${filtered?.length} / ${boxes?.length} Postkasser`}</FormText>
            </Col>
            <Col xs="auto">
              <MyExportCSV {...props.csvProps} />
            </Col>
          </Row>
          <Button
            onClick={() => {
              history.push(`/postbox/new`);
            }}
            className="m-2 fs--1 rounded-pill"
            size="sm"
            color="outline-primary"
          >
            <FontAwesomeIcon icon="plus" />
            {" Legg til"}
          </Button>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              sizePerPage: 300,
              totalSize: isIterableArray(filtered) && filtered.length,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              const lastIndex =
                paginationProps.page * paginationProps.sizePerPage;

              return (
                <>
                  <Nav tabs>
                    <NavItem className="cursor-pointer">
                      <NavLink
                        active={activeTab === "list"}
                        onClick={() => setActiveTab("list")}
                      >
                        Liste
                      </NavLink>
                    </NavItem>
                    {/* <NavItem className='cursor-pointer'>
                                    <NavLink active={activeTab === 'map'} onClick={() => setActiveTab('map')}>Kart</NavLink>
                                </NavItem> */}
                  </Nav>
                  {activeTab === "list" && (
                    <div className="table-responsive  mb-4">
                      <BootstrapTable
                        {...props.baseProps}
                        hover
                        bordered={false}
                        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-left border-right border-200"
                        rowClasses="btn-reveal-trigger border-top border-200 cursor-pointer"
                        headerClasses="bg-200 text-900 border-y border-200"
                        rowEvents={tableOptions}
                        defaultSorted={[{ dataField: "name", order: "asc" }]}
                        {...paginationTableProps}
                      />
                    </div>
                  )}
                  {activeTab === "list" &&
                    isIterableArray(filtered) &&
                    filtered.length > 20 && (
                      <Row noGutters className="px-1 py-3 flex-center">
                        <Col xs="auto">
                          <Button
                            color="falcon-default"
                            size="sm"
                            onClick={handlePrevPage(paginationProps)}
                            disabled={paginationProps.page === 1}
                          >
                            <FontAwesomeIcon icon="chevron-left" />
                          </Button>
                          {getPaginationArray(
                            paginationProps.totalSize,
                            paginationProps.sizePerPage
                          ).map((pageNo) => (
                            <Button
                              color={
                                paginationProps.page === pageNo
                                  ? "falcon-primary"
                                  : "falcon-default"
                              }
                              size="sm"
                              className="ml-2"
                              onClick={() =>
                                paginationProps.onPageChange(pageNo)
                              }
                              key={pageNo}
                            >
                              {pageNo}
                            </Button>
                          ))}
                          <Button
                            color="falcon-default"
                            size="sm"
                            className="ml-2"
                            onClick={handleNextPage(paginationProps)}
                            disabled={lastIndex >= paginationProps.totalSize}
                          >
                            <FontAwesomeIcon icon="chevron-right" />
                          </Button>
                        </Col>
                      </Row>
                    )}
                  {activeTab === "map" && (
                    <div>
                      <AddressMap
                        addresses={Object.values(
                          boxes
                            .map((value) => {
                              return {
                                address:
                                  (value.street ?? "") +
                                  " " +
                                  (value.zip ?? "") +
                                  " " +
                                  (value.city ?? ""),
                                name: value.box_address,
                              };
                            })
                            .reduce((acc, curr) => {
                              if (!acc[curr.address]) {
                                acc[curr.address] = {
                                  address: curr.address,
                                  name: [curr.name],
                                };
                              } else {
                                acc[curr.address].name = `${
                                  acc[curr.address].name
                                } , ${curr.name}`;
                              }
                              return acc;
                            }, {})
                        )}
                      />
                    </div>
                  )}
                </>
              );
            }}
          </PaginationProvider>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default PostBoxes;
