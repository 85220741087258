import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import config from "../../config";

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  authDomain: config.firebaseAuthDomain,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
  messagingSenderId: config.firebaseMessagingSenderId,
  appId: config.firebaseAppId,
};

//console.log(firebaseConfig);
// initialize Firebase app
firebase.initializeApp(firebaseConfig);

export default firebase;
