import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup,
  Alert,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import {
  fetchCustomers,
  fetchResident,
  fetchBoxes,
  fetchResidentAccesses,
  fetchCustomerBuildings,
  fetchBuildingBoxes,
} from "../../../data/dataFetch";
import React, { useState, useEffect } from "react";
import {
  updateResident,
  createResident,
  deleteResident,
  deleteAccess,
  createAccess,
} from "../../../data/dataUpdate";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { isIterableArray } from "../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import TableSearchable from "../../common/TableSearchable";
import moment from "moment";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";

const Resident = () => {
  const { id } = useParams();
  const [isNew, setIsNew] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [initialResident, setInitialResident] = useState({
    customer_id: 0,
    box_id: 0,
    building_id: 0,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    zip: "",
    city: "",
    state: "",
    box_text: "",
  });
  const [resident, setResident] = useState(initialResident);
  const [customers, setCustomers] = useState([]);
  const [customerBuildings, setCustomerBuildings] = useState([]);
  const [buildingBoxes, setBuildingBoxes] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const history = useHistory();
  const [addAccess, setAddAccess] = useState(false);
  const [accesses, setAccesses] = useState([]);
  const [access, setAccess] = useState({
    access_name: "",
    resident_id: parseInt(id.split("-")[0], 0),
    user_id: parseInt(id.split("-")[1], 0),
    valid_from: new Date(),
    valid_to: new Date(),
    box_id: resident.box_id,
    device_id: "",
    building_id: resident.building_id,
  });
  const [smartCardList, setSmartCardList] = useState({
    smartcard_device_id1: "",
    smartcard_device_id2: "",
    smartcard_device_id3: "",
    smartcard_device_id4: "",
    smartcard_device_id5: "",
  });

  useEffect(() => {
    const loadResident = async () => {
      var data = await fetchResident(id);
      var copy = JSON.parse(JSON.stringify(data));
      setIsNew(false);
      setInitialResident(copy);
      setResident(copy);
      loadCustomerBuildings(copy.customer_id);
      loadBuildingBoxes(copy.building_id);
      loadAccesses(copy.resident_id, copy.user_id);
    };
    if (id === "new") {
      setInitialResident({
        customer_id: 0,
        box_id: 0,
        building_id: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        zip: "",
        city: "",
        state: "",
        box_text: "",
      });
      setResident(initialResident);
      setIsNew(true);
    } else loadResident();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [accesses]);

  const loadAccesses = async (residentID, userID) => {
    var data = (await fetchResidentAccesses(residentID, userID)) ?? [];
    setAccesses(data);
  };

  useEffect(() => {
    const loadCustomers = async () => {
      setCustomers(await fetchCustomers());
    };
    loadCustomers();
  }, []);

  const isResidentUnchanged = () => {
    return JSON.stringify(initialResident) === JSON.stringify(resident);
  };
  useEffect(() => {
    const loadBoxes = async () => {
      setBoxes(await fetchBoxes());
    };
    loadBoxes();
  }, []);

  const loadCustomerBuildings = async (customerId) => {
    setCustomerBuildings(await fetchCustomerBuildings(customerId));
  };

  const loadBuildingBoxes = async (buildingId) => {
    setBuildingBoxes(await fetchBuildingBoxes(buildingId));
  };

  const insertOrUpdateResident = async () => {
    async function addSmartCardDevices() {
      resident.smartcard_devices = [];
      Object.keys(smartCardList).forEach((key) => {
        const value = smartCardList[key];
        if (value !== null && value !== undefined && value !== "") {
          resident.smartcard_devices.push(value);
        }
      });
    }
    async function insertResident() {
      await addSmartCardDevices();
      const res = await createResident(resident);
      if (res) {
        setIsEditing(false);
        setInitialResident(resident);
        toast.success("beboer er lagt til");
      } else toast.error("Det har oppstått en feil ");
    }
    async function modifyResident() {
      const res = await updateResident(resident);
      if (res.status === 200) {
        setIsEditing(false);
        setInitialResident(resident);
        toast.success("beboer er oppdatert");
      } else toast.error("Det har oppstått en feil ");
    }
    if (isNew) await insertResident();
    else await modifyResident();
  };

  const saveAccess = (currentAccess) => {
    currentAccess.box_id = resident.box_id;
    currentAccess.user_id = resident.user_id;
    createAccess(currentAccess);
    loadAccesses(resident.resident_id, resident.user_id);
  };

  const deleteCurrentResident = async (residentId) => {
    let result = await confirm({
      title: "Bekrefte sletting",
      message: "Bekrefte sletting.",
      confirmText: "Bekrefte",
      cancelText: "Avbryt",
    });
    if (result) {
      await deleteResident(residentId);
      history.push(`/`);
    }
  };

  const deleteCurrentAccess = async (accessId) => {
    let result = await confirm({
      title: "Bekrefte sletting",
      message: "Bekrefte sletting.",
      confirmText: "Bekrefte",
      cancelText: "Avbryt",
    });
    if (result) {
      await deleteAccess(accessId);
      loadAccesses(resident.resident_id, resident.user_id);
    }
  };

  const accessColumns = [
    {
      accessor: "access_name",
      Header: "Tilgangsnavn",
    },
    {
      accessor: "full_name",
      Header: "Beboer",
    },

    {
      accessor: "device_id",
      Header: "Enhets-ID",
    },
    {
      accessor: "box_address",
      Header: "Postkasse-ID",
    },
    {
      accessor: "valid_from",
      Header: "Gyldig fra",
    },
    {
      accessor: "valid_to",
      Header: "Gyldig til",
    },
    {
      Headers: "",
      accessor: "access_id",
      Cell: (props) => {
        const row = props.row.original;
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                deleteCurrentAccess(row.access_id);
              }}
              color="outline-primary"
              size="sm"
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </div>
        );
      },
      maxWidth: 50,
    },
  ];

  return (
    <>
      <Modal show={addAccess} size="lg">
        <ModalHeader>Navn</ModalHeader>
        <ModalBody>
          <div className="mb-5">
            <Label>Tilgangsnavn</Label>

            <Input
              value={access.access_name}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={({ target }) => {
                setAccess({ ...access, access_name: target.value });
              }}
            />

            <Label>Enhets-ID</Label>
            <Input
              value={access.device_id}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={({ target }) => {
                setAccess({ ...access, device_id: target.value });
              }}
            />

            <Label>Gyldig fra</Label>
            <Input
              type="datetime-local"
              value={moment(access.valid_from).format("YYYY-MM-DDTHH:mm:ss")}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={({ target }) => {
                setAccess({ ...access, valid_from: new Date(target.value) });
              }}
            />

            <Label>Gyldig til</Label>
            <Input
              type="datetime-local"
              value={moment(access.valid_to).format("YYYY-MM-DDTHH:mm:ss")}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={({ target }) => {
                setAccess({ ...access, valid_to: new Date(target.value) });
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              saveAccess(access);
              setAddAccess(false);
              loadAccesses(resident.resident_id, resident.user_id);
            }}
          >
            Bruk
          </Button>
          <Button color="warning" onClick={() => setAddAccess(false)}>
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>

      <div className="mb-5">
        <Card>
          <Row className="align-items-center p-3">
            <Col>
              <h5>
                {isNew ? "NY" : ""} BEBOER{" "}
                {!isNew ? resident.first_name + " " + resident.last_name : ""}
              </h5>
            </Col>
            <Col xs="auto" className="text-right">
              <Link to={`/`} className="mr-2">
                <Button color="primary" size="sm">
                  {" "}
                  &lt; Beboere
                </Button>
              </Link>
              {!isNew && (
                <Button
                  onClick={() => {
                    deleteCurrentResident(id);
                  }}
                  color="danger"
                  size="sm"
                >
                  Slett
                </Button>
              )}{" "}
              <Button
                onClick={() => {
                  if (isEditing) {
                    setResident(JSON.parse(JSON.stringify(initialResident)));
                    if (isNew) {
                      history.push(`/`);
                    }
                  }
                  setIsEditing(!isEditing);
                }}
                color="warning"
                size="sm"
              >
                Avbryt
              </Button>
            </Col>
          </Row>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                  <Label>Kunde</Label>
                  <Select
                    options={
                      isIterableArray(customers) &&
                      Object.values(customers).map((customer) => ({
                        value: customer.customer_id,
                        label: customer.customer_name,
                      }))
                    }
                    value={{
                      value: resident.customer_id,
                      label: isIterableArray(customers)
                        ? (
                            customers.find(
                              (v) =>
                                v.customer_id === Number(resident.customer_id)
                            ) ?? {}
                          ).customer_name
                        : "",
                    }}
                    onChange={({ value }) => {
                      var customer = customers.find(
                        (v) => v.customer_id === Number(value)
                      );
                      setResident({
                        ...resident,
                        customer_id: value,
                        customer_name: customer.customer_name,
                      });
                      loadCustomerBuildings(value);
                    }}
                  />
                </FormGroup>
              </Col>
              {!isNew && (
                <Col>
                  <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                    <Label>Postkasse</Label>
                    <Select
                      options={
                        isIterableArray(boxes) &&
                        Object.values(boxes).map((box) => ({
                          value: box.box_id,
                          label: box.box_address,
                        }))
                      }
                      value={{
                        value: resident.box_address,
                        label: isIterableArray(boxes)
                          ? (
                              boxes.find(
                                (v) => v.box_id === Number(resident.box_id)
                              ) ?? {}
                            ).box_address
                          : "",
                      }}
                      onChange={({ value }) => {
                        setResident({ ...resident, box_id: value });
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {isNew && (
                <>
                  <Col>
                    <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                      <Label>Bygning</Label>
                      <Select
                        options={
                          isIterableArray(customerBuildings)
                            ? Object.values(customerBuildings).map(
                                (building) => ({
                                  value: building.building_id,
                                  label: building.building_name,
                                })
                              )
                            : []
                        }
                        value={{
                          value: resident.building_id,
                          label: isIterableArray(customerBuildings)
                            ? (
                                customerBuildings.find(
                                  (v) =>
                                    v.building_id ===
                                    Number(resident.building_id)
                                ) ?? {}
                              ).building_name
                            : "",
                        }}
                        onChange={({ value }) => {
                          var building = customerBuildings.find(
                            (v) => v.building_id === Number(value)
                          );
                          setResident({
                            ...resident,
                            building_id: value,
                            building_name: building.building_name,
                          });
                          loadBuildingBoxes(value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                      <Label>Postkasse</Label>
                      <Select
                        options={
                          isIterableArray(buildingBoxes)
                            ? Object.values(buildingBoxes).map((box) => ({
                                value: box.box_id,
                                label: box.box_address,
                              }))
                            : []
                        }
                        value={{
                          value: resident.box_id,
                          label: isIterableArray(buildingBoxes)
                            ? (
                                buildingBoxes.find(
                                  (v) => v.box_id === Number(resident.box_id)
                                ) ?? {}
                              ).box_address
                            : "",
                        }}
                        onChange={({ value }) => {
                          var box = buildingBoxes.find(
                            (v) => v.box_id === Number(value)
                          );
                          setResident({
                            ...resident,
                            box_id: value,
                            box_address: box.box_address,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <Col>
                <FormGroup className="mr-2 mb-2">
                  <Label>Fornavn</Label>
                  <Input
                    value={resident.first_name}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onChange={({ target }) => {
                      setResident({ ...resident, first_name: target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mr-2 mb-2">
                  <Label>Etternavn</Label>
                  <Input
                    value={resident.last_name}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onChange={({ target }) => {
                      setResident({ ...resident, last_name: target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className="mr-2 mb-2">
                  <Label>Epost</Label>
                  <Input
                    value={resident.email}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onChange={({ target }) => {
                      setResident({ ...resident, email: target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mr-2 mb-2">
                  <Label>Telefon</Label>
                  <Input
                    value={resident.phone}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onChange={({ target }) => {
                      setResident({ ...resident, phone: target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isNew && (
              <FormGroup className="mr-2 mb-2">
                <Label>Postkasse display tekst</Label>
                <Input
                  value={resident.box_text}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={({ target }) => {
                    setResident({ ...resident, box_text: target.value });
                  }}
                />
              </FormGroup>
            )}
            {isNew && (
              <Row>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 1</Label>
                    <Input
                      value={smartCardList.smartcard_device_id1}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id1: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 2</Label>
                    <Input
                      value={smartCardList.smartcard_device_id2}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id2: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 3</Label>
                    <Input
                      value={smartCardList.smartcard_device_id3}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id3: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {isNew && (
              <Row>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 4</Label>
                    <Input
                      value={smartCardList.smartcard_device_id4}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id4: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 5</Label>
                    <Input
                      value={smartCardList.smartcard_device_id5}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id5: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mr-2 mb-2">
                    <Label>Nøkkelkort 6</Label>
                    <Input
                      value={smartCardList.smartcard_device_id6}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={({ target }) => {
                        setSmartCardList({
                          ...smartCardList,
                          smartcard_device_id6: target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CardBody>
          <Row className="align-items-center p-3">
            {!isNew && (
              <Col xs="auto" className="text-right">
                {" "}
                <Button
                  onClick={() => setAddAccess(true)}
                  color="outline-primary"
                  size="sm"
                >
                  <FontAwesomeIcon icon="plus" />
                  Legg til korttilgang
                </Button>{" "}
                <Button onClick={() => {}} color="outline-primary" size="sm">
                  Send parringskode på nytt
                </Button>
              </Col>
            )}
          </Row>
          <>
            {!isNew && (
              <div className="ml-3 mr-3 mb-2">
                <TableSearchable
                  columns={accessColumns}
                  data={
                    accesses &&
                    accesses.map((value) => {
                      if (value) {
                        const full_name =
                          (value?.first_name ?? "") +
                          " " +
                          (value?.last_name ?? "");
                        return {
                          ...value,
                          full_name: full_name,
                        };
                      }
                      return null;
                    })
                  }
                  size={100}
                />
              </div>
            )}
          </>
        </Card>

        {isResidentUnchanged() ? null : (
          <div className="fixed-top">
            <Alert color="danger">
              <Row>
                <Col sm="auto">
                  <Button
                    onClick={() => insertOrUpdateResident()}
                    color="primary"
                  >
                    Bruk
                  </Button>{" "}
                  {!isNew && <Button color="danger">Tilbakstil</Button>}
                </Col>
                <Col className="mt-2" sm="auto">
                  <span>Vil du lagre endringene?</span>
                </Col>
              </Row>
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default Resident;
